import { CONSOLE, Console } from "./types";

export const initialState: Console = {
  loaded: false,
};

export default function console(state = initialState, action: any) {
  switch (action.type) {
    case CONSOLE.LOAD: {
      return {
        ...state,
        loaded: true,
      };
    }

    default: {
      return state;
    }
  }
}
