import consoleReducer from "./console";

import { logger } from "./middlewares";

interface State {
  console: any;
}

export const initialState: State = {
  console: consoleReducer.initialState,
};

export default function mainReducer(state: State, action: object) {
  // Receiving previous state here
  const { console } = state;

  // Receiving current state here
  const currentState = {
    console: consoleReducer.reducer(console, action),
  };

  // Middlewares
  // logger(action, state, currentState);

  return currentState;
}
