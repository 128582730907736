import { CONSOLE } from "./types";

export function load() {
  return {
    type: CONSOLE.LOAD,
  };
}

export function handleLoad() {
  return load();
}
